import React from 'react';
import ProjectCard from './components/ProjectCard/ProjectCard';
import './App.css';
import {Navbar} from "./components/Navbar/Navbar";
import MainCard from "./components/MainCard/MainCard";
import BlockInfoBar from './components/BlockInfoBar/BlockInfoBar';
import CompanyCard from "./components/CompanyCard/CompanyCard";
import {Footer, FooterBar} from "./components/Footer/Footer";
import NomoNewsCTA from "./components/NomoNewsCTA/NomoNewsCTA";
import '../src/components/NomoNewsCTA/NomoNewsCTA.css';
import {FaWikipediaW} from "react-icons/fa";
import { IoAirplaneSharp } from "react-icons/io5";

const App = () => {
	const projects = [
		{
			title: "Nomo App",
			description: "A user-friendly DeFi platform for managing your digital assets and interacting with the ecosystem.",
			imageUrl: "/images/nomologo.png",
			link: "https://nomo.app"
		},
		{
			title: "ZENIQ Smart Chain",
			description: "A high-performance blockchain network designed for DeFi and smart contract applications.",
			imageUrl: "/images/smart.png",
			link: "https://www.zeniq.dev/docs/zeniq-smartchain/zeniqSmartchainOverview1"
		},
		{
			title: "ZENIQ Explorer",
			description: "A powerful tool for tracking transactions, verifying data, and exploring the ZENIQ Blockchain network with complete transparency and real-time insights.",
			imageUrl: "/images/explorer.png",
			link: "https://zeniqblocks.com/"
		},
		{
			title: "Minting Nodes",
			description: "Decentralized Infrastructure to participate and collaborate with the growth of diverse DeFi projects running on the ZENIQ Ecosystem.",
			imageUrl: "/images/minting.png"
		},
		{
			title: "ZENIQ Blockchain",
			description: "The ecosystem's core blockchain technology, providing a secure and scalable foundation for decentralized applications.",
			imageUrl: "/images/zeniqlogo.png",
			link: "https://docs.zeniq.wiki/"
		},
		{
			title: "ZENIQ Hub",
			description: "An All-in-one blockchain hardware solution, acting as a node, a wallet and an interface for managing your assets and accessing dApps.",
			imageUrl: "/images/hub.png"
		}
	];
	
	const contributors = [
		{
			description: "Based in Austria, VOO is leading innovations in the business aviation industry, revolutionizing travel through blockchain.",
			imageUrl: "/images/voologo.png",
			link: "https://voo.aero"
		},
		{
			description: "Through WebOn technology, NOMO exponentially increased the birth of web3 solutions in the ecosystem.",
			imageUrl: "/images/nomologo-extended.png",
			link: "https://nomo.app"
		},
		{
			description: "A platform capable of accessing and managing all main minting projects running inside the ZENIQ Ecosystem.",
			imageUrl: "/images/kmintingsdark.svg",
			link: "https://kmintings.com/"
		},
	];
	
	const industries = [
		{
			description: "Join the VOO ONE cooperative.",
			imageUrl: "/images/vooone.svg",
			link: "https://voo.aero"
		},
		{
			description: "Maximize your Yield Potential with AVINOC DeFi.",
			imageUrl: "/images/avinocdefi.svg",
			link: "https://kmintings.com/"
		},
		{
			description: "Start with Supersonic.",
			imageUrl: "/images/supersonic.svg",
			link: "https://nomo.app"
		},
		{
			description: "The VOO minters marketplace.",
			imageUrl: "/images/vooshop.png",
			link: "https://nomo.app"
		},
	
	];
	
	return (
		<>
			<header className="header">
				<BlockInfoBar/>
				<Navbar/>
				<div className="container">
					<div className="text-1">Development Update</div>
					<h1>MISSION ACCOMPLISHED</h1>
					<div className="text-1">A successful implementation of the ZENIQ Whitepaper</div>
					<MainCard></MainCard>
					<div className="text-2">
						In 2020, <strong>ZENIQ Company</strong> embarked on a mission to foster financial freedom by
						providing easy and
						secure access to blockchain technology. With a vision to become the most popular and secure
						portal for digital investments, ZENIQ set out to build a leading digital exchange and offer
						cutting-edge blockchain-based services. To achieve this, the company laid out a detailed
						<strong> whitepaper</strong>, mapping the path toward the <strong>ZENIQ Ecosystem</strong> — an
						infrastructure designed as a fully
						decentralized blockchain solution. It promised a combination of <strong>innovative hardware and
						software
						tools </strong> that would establish trust in cryptocurrencies and tokenization.
					</div>
				</div>
			
			</header>
			<div className="container">
				<div className="section-title">Blockchain Infrastructure Solutions</div>
				<div className="section-text" style={{textAlign: "center", borderLeft: "none"}}>Going beyond the
					Whitepaper, other initially
					non-predicted core solutions,<br/> such
					as the Smartchain, were implemented.
				</div>
				<main className="main">
					{projects.map((project, index) => (
						<ProjectCard key={index} {...project} />
					))}
				</main>
			</div>
			<div className="container">
				<div className="text-2">
					Driven by this vision, ZENIQ Company worked tirelessly, <strong>collaborating with
					industry experts and
					partners, to build a secure, high-quality system</strong>. This effort resulted in the realization
					of the
					ZENIQ Ecosystem, a decentralized blockchain infrastructure with the ability to <strong>self-sustain
					and
					grow</strong> through <strong>open-source</strong> development supported by a <strong>global
					community</strong>.
				</div>
			</div>
			<div className="container">
				<div className="section-title">The Main Contributors of the Ecosystem</div>
				<div className="section-text" style={{textAlign: "center", borderLeft: "none"}}>With ZENIQ company's job
					accomplished, the
					ecosystem is now able to
					grow<br/>
					in multiple directions, led by the vision of several key organizations, with a honorable mention to:
				</div>
				<main className="main" style={{marginBottom: "3rem"}}>
					{contributors.map((project, index) => (
						<CompanyCard key={index} {...project} />
					))}
				</main>
				<div className="text-2">
					Having <strong>successfully fulfilled its mission</strong>, ZENIQ Company has now stepped aside,
					allowing its
					creation to evolve freely. <strong>"ZENIQ Unchained"</strong> marks a new era where the ecosystem,
					once limited
					by the vision and resources of a single entity, is now poised to reach its <strong>full
					potential </strong> through <strong>collective innovation</strong> and the <strong>power of
					decentralization</strong>. <br/> <br/>
				</div>
				<div className="text-2">
					The <strong>ZENIQ Ecosystem</strong> is presently experiencing a <strong>growth in the variety of
					industries</strong>
					taking advantage
					of blockchain technology to disrupt their markets. Some of the main solutions flourishing
					inside
					this blockchain infrastructure are worth the highlight below:
				</div>
			</div>
			<div className="container">
				
				<div className="section-title">Dedicated Ecosystem DeFi Solutions</div>
				<div className="section-text">The ZENIQ Ecosystem is reshaping decentralized finance with cutting-edge
					dApps designed for a frictionless, efficient, and profitable trading experience. Our DEX
					(Decentralized Exchange) enables secure and permissionless asset trading, ensuring transparency and
					full user control. LIQ is our dedicated liquidity solution, allowing users to provide and access
					deep liquidity pools for optimized market stability. ARB empowers traders with real-time arbitrage
					insights, identifying price differences across markets to maximize profit potential. Together, these
					innovations unlock new possibilities for DeFi participants, driving a more connected and efficient
					Web3 financial ecosystem:<br/>
				</div>
				<a className="image-list" href="https://discover.webon.info">
					<img className="industry-image" src="/images/liq.svg"/>
					<img className="industry-image" src="/images/dex.svg"/>
					<img className="industry-image" src="/images/arb.svg"/>
				</a>
				
				<div className="section-title">Aerospace Industry Solutions</div>
				<div className="section-text">Led by VOO, the ZENIQ Ecosystem is pioneering a range of Web3 Aerospace
					applications, transforming the aerospace industry with blockchain-powered solutions. By integrating
					decentralized technologies, these applications offer new levels of transparency, security, and
					efficiency. This innovation increases trust and efficiency, allowing the aerospace sector to explore
					untapped potentials:<br/>
				</div>
				<a className="image-list" href="https://discover.webon.info">
					<img className="industry-image" src="/images/vooone.svg"/>
					<img className="industry-image" src="/images/avinocdefi.svg"/>
					<img className="industry-image" src="/images/vooshop.png"/>
					<img className="industry-image" src="/images/avinocincentive.svg"/>
					<img className="industry-image" src="/images/voogold.png"/>
					<img className="industry-image" src="/images/supersonic.svg"/>
				</a>
				<div style={{
					background: "#F5F0E4FF",
					borderRadius: '10px',
					padding: '1rem',
					color: 'black',
				}}>
					<div className="section-title">VOO Ecosystem Overview</div>
					<div className="section-text-yellow">The VOO Ecosystem is a dynamic,
						interconnected network where aviation, blockchain, and decentralized finance converge. Designed
						to seamlessly integrate with the ZENIQ Ecosystem, it leverages tokenization, smart contracts,
						and real-world utility to create a fully connected digital infrastructure. Every element - whether
						flight booking, rewards, payments, or investment opportunities - flows effortlessly within this
						ecosystem, ensuring efficiency, security, and transparency. This structured yet adaptable
						framework empowers users, businesses, and partners to explore new opportunities without
						complexity, making Web3 aviation and finance more accessible than ever.<br/>
					</div>
					<a
						href="https://miro.com/app/board/uXjVLKPFM_8=/?share_link_id=42024312419/"
						className="wiki-button"
						target="_blank"
						rel="noopener noreferrer"
					>
						<IoAirplaneSharp className="cta-icon"/> Access VOO Ecosystem Overview
					</a>
				</div>
				
				<div className="section-title">Real Estate Industry</div>
				<div className="section-text">The ZENIQ Ecosystem seamlessly merges real estate with Web3, offering
					luxury properties, hotels, and premium travel experiences that are not only exceptional on their own
					but also perfectly integrated with aerospace solutions. Tokenization of real estate assets within
					the ecosystem enables fractional ownership, transparent transactions, and borderless investments,
					creating synergies between travel, hospitality, and high-end property management. This harmony
					enriches both industries, offering unparalleled convenience and new opportunities for global
					investors:<br/>
				</div>
				<a className="image-list" href="https://discover.webon.info">
					<img className="industry-image" src="/images/dynasty.png"/>
				</a>
				
				<div className="section-title">On Ramp And Off Ramp Solutions</div>
				<div className="section-text">Seamless integration between traditional finance and the crypto world
					is essential for widespread adoption. The ZENIQ Ecosystem offers robust on-ramp and off-ramp
					solutions that enable users to effortlessly convert between fiat currencies and digital assets.
					These services provide a user-friendly gateway for both newcomers and seasoned investors,
					simplifying transactions and participation across the ecosystem. By streamlining the process
					of entering and exiting the crypto space, these solutions enhance accessibility and foster
					greater engagement with the innovative applications within the ecosystem.
					<br/>
				</div>
				<a className="image-list" href="https://discover.webon.info">
					<img className="industry-image" src="/images/transferoramp.png"/>
					<img className="industry-image" src="/images/moonpay.svg"/>
				</a>
				
				<div className="section-title">DeFi Management Applications</div>
				<div className="section-text">At the core of Web3 technology lies decentralized finance (DeFi), and the
					ZENIQ Ecosystem boasts a robust portfolio of DeFi management applications. These solutions empower
					users to manage digital assets, participate in decentralized lending, borrowing, staking, and
					liquidity provision—all from a secure, transparent, and decentralized platform. By simplifying
					complex financial processes and offering tools for enhanced control, these applications democratize
					finance, making it accessible to users across the globe while unlocking the full potential of
					blockchain:
					<br/>
				</div>
				<a className="image-list" href="https://discover.webon.info">
					<img className="industry-image" src="/images/benefit.svg"/>
					<img className="industry-image" src="/images/zemi_card.svg"/>
					<img className="industry-image" src="/images/zusd.svg"/>
					<img className="industry-image" src="/images/avinocswap.svg"/>
					<img className="industry-image" src="/images/nft.svg"/>
					<img className="industry-image" src="/images/zenpass.svg"/>
				</a>
				
				<div className="section-title">DeFi Gaming</div>
				<div className="section-text">Combining entertainment with financial growth, DeFi Gaming within the
					ZENIQ Ecosystem allows users to earn rewards while enjoying immersive gaming experiences. By
					integrating play-to-earn models, NFTs, and staking mechanisms, users can grow their digital assets
					in a fun and engaging way. This fusion of gaming and decentralized finance encourages active
					participation in the ecosystem, blurring the lines between leisure and financial opportunity, while
					promoting user engagement and innovation in the gaming sector:<br/>
				</div>
				<a className="image-list" href="https://discover.webon.info">
					<img className="industry-image" src="/images/flaptron.webp"/>
				</a>
				
				<div style={{backgroundColor: "white", width: "100vw"}}>
					<FooterBar/>
					<div className="container">
						<div className="section-title">And Much More at Discover WebOns</div>
						<div className="section-text">Discover WebOns is the ultimate gateway to explore not only the
							solutions mentioned above but also the latest DeFi projects launching in the ZENIQ
							Ecosystem.
							Acting as a comprehensive platform, it connects users with cutting-edge decentralized
							applications (dApps), while integrating seamlessly with the NOMO App for a frictionless user
							experience. Whether you’re exploring aerospace, real estate, or gaming solutions, Discover
							WebOns ensures that all your Web3 needs are conveniently accessible in one place, making the
							installation and management of dApps effortless.
							<br/>
						</div>
					</div>
					<a className="image-list" href="https://discover.webon.info">
						<img className="industry-image" src="/images/discover.svg"/>
					</a>
					<div className="container">
						<div className="section-title">Visit ZENIQ Wiki for More Information</div>
						<div className="text-2">Explore the world of ZENIQ, where innovation meets blockchain
							technology. The ZENIQ ecosystem is designed to empower users with cutting-edge solutions and
							provide a seamless experience across various platforms.
							
							At the ZENIQ Wiki, you can browse through different pages to learn more about the ZENIQ
							Blockchain, its capabilities, and the diverse range of projects built on it.
							<br/>
						</div>
						<a
							href="https://docs.zeniq.wiki/"
							className="wiki-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaWikipediaW className="cta-icon"/> ZENIQ WIKI
						</a>
					</div>
				
				</div>
			</div>
			<NomoNewsCTA/>
			<Footer/>
		</>
	);
};

export default App;